import { memo } from 'react'
import type { SVGProps } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 25" {...props}>
    <path
      fill="currentColor"
      d="m10.007 9.323 5.1 2.899-5.1 2.898V9.323ZM24 2.783V21.64a2.572 2.572 0 0 1-2.571 2.57H2.57A2.572 2.572 0 0 1 0 21.64V2.782A2.572 2.572 0 0 1 2.571.211H21.43A2.572 2.572 0 0 1 24 2.782Zm-2.25 9.444s0-3.193-.407-4.725a2.445 2.445 0 0 0-1.725-1.736C18.102 5.354 12 5.354 12 5.354s-6.102 0-7.618.412c-.84.225-1.5.89-1.725 1.736-.407 1.527-.407 4.725-.407 4.725s0 3.193.407 4.725a2.41 2.41 0 0 0 1.725 1.709c1.516.407 7.618.407 7.618.407s6.102 0 7.618-.412a2.41 2.41 0 0 0 1.725-1.71c.407-1.526.407-4.719.407-4.719Z"
    />
  </svg>
)

const Memo = memo(SvgComponent)

export default Memo

import { memo } from 'react'
import type { SVGProps } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 25" {...props}>
    <path
      fill="currentColor"
      d="M22.286.21H1.709C.766.21 0 .989 0 1.942v20.54c0 .953.766 1.73 1.709 1.73h20.577c.943 0 1.714-.777 1.714-1.73V1.94c0-.953-.771-1.73-1.714-1.73ZM7.254 20.783H3.696V9.33H7.26v11.453h-.005ZM5.475 7.765a2.063 2.063 0 0 1 0-4.125c1.136 0 2.063.926 2.063 2.062a2.06 2.06 0 0 1-2.063 2.063Zm15.112 13.017H17.03v-5.571c0-1.329-.026-3.038-1.848-3.038-1.853 0-2.137 1.447-2.137 2.941v5.668H9.488V9.33H12.9v1.564h.048c.477-.9 1.64-1.848 3.37-1.848 3.6 0 4.27 2.373 4.27 5.459v6.278Z"
    />
  </svg>
)

const Memo = memo(SvgComponent)

export default Memo

import { Link, useFetcher } from '@remix-run/react'
import classnames from 'classnames'
import { Button } from '~/components/design-system/button'
import Checkbox from '~/components/design-system/form/checkbox'
import { MAX_WIDTH, PADDING } from '~/components/landing-pages/constants'
import RoutescannerLogoWide from '~/icons/routescanner-logo-wide'
import * as Socials from '~/icons/socials'

type FetcherData = {
  success: boolean
  errors?: {
    path: string
    message: string
  }[]
}

export default function Footer() {
  const fetcher = useFetcher<FetcherData>()

  const emailError = fetcher.data?.errors?.some(error => error.path.includes('email'))
  const privacyPolicyError = fetcher.data?.errors?.some(error => error.path.includes('privacyPolicy'))

  return (
    <footer className="mt-20 flex flex-col bg-brand-darkest text-base text-white">
      <div
        className={classnames(
          'grid grid-cols-1 gap-y-12 py-10',
          'md:grid-cols-[max-content,repeat(4,1fr)] md:grid-rows-[repeat(2,auto)] md:gap-x-4 md:gap-y-20 md:py-20',
          MAX_WIDTH,
          PADDING
        )}>
        <div className={classnames('hidden', 'xl:row-span-full xl:block', 'xl:pr-14')}>
          <RoutescannerLogoWide className="w-[100px]" />
        </div>

        <div className="space-y-6">
          <h4 className="font-bold">Solutions</h4>

          <ul className="space-y-6 opacity-80">
            <li>
              <Link to="/features/route-optimizer">Route Optimizer</Link>
            </li>

            <li>
              <Link to="/features/emissions-calculation">Emissions Reporting</Link>
            </li>

            <li>
              <Link to="/features/distance-emissions-api">Distance & Emissions API</Link>
            </li>

            <li>
              <Link to="/features/direct-connections">Embedded Direct Connections</Link>
            </li>
          </ul>
        </div>

        <div className="space-y-6">
          <h4 className="font-bold">Industries</h4>

          <ul className="space-y-6 opacity-80">
            <li>
              <Link to="/industries/shippers-and-cargo-owners">Shippers & Cargo Owners</Link>
            </li>

            <li>
              <Link to="/industries/freight-forwarders">Freight Forwarders</Link>
            </li>

            <li>
              <Link to="/industries/carriers-and-operators">Carriers & Operators</Link>
            </li>

            <li>
              <Link to="/features/direct-connections">Ports & Terminals</Link>
            </li>
          </ul>
        </div>

        <div className="space-y-6">
          <h4 className="font-bold">Resources</h4>

          <ul className="space-y-6 opacity-80">
            <li>
              <Link to="/partners">Connected Operators</Link>
            </li>

            <li>
              <Link to="/schedule-coverage">Schedule Coverage</Link>
            </li>

            <li>
              <Link to="/locodes">Connected Ports & Inland Hubs</Link>
            </li>

            <li>
              <Link to="https://docs.routescanner.com/">API Documentation</Link>
            </li>

            <li>
              <Link to="/glec">GLEC Emissions Calculation Method</Link>
            </li>

            <li>
              <Link to="/SIG">SIG Data Neutrality</Link>
            </li>

            <li>
              <Link to="/ISO-14083">ISO Compliance</Link>
            </li>
          </ul>
        </div>

        <div className="space-y-6">
          <h4 className="font-bold">About us</h4>

          <ul className="space-y-6 opacity-80">
            <li>
              <Link to="/contact">Contact us</Link>
            </li>

            <li>
              <Link to="/news-room">In the media</Link>
            </li>

            <li>
              <Link to="/news">Blog & News</Link>
            </li>

            <li>
              <Link to="/events">Events</Link>
            </li>

            <li>
              <Link to="/about">Our Mission</Link>
            </li>

            <li>
              <Link to="/careers">Careers</Link>
            </li>
          </ul>
        </div>

        <div className={classnames('space-y-4 text-base', 'md:col-span-2')}>
          <h4 className="font-black">Join the Routescanner mailing list</h4>

          <p className="opacity-80">Get the latest updates about new features and best practices in optimizing your tradelanes</p>

          {fetcher.data?.success !== true ? (
            <fetcher.Form action="/api/newsletter/v2" method="POST" className="flex flex-col items-center gap-y-4">
              <input
                required
                type="email"
                name="email"
                placeholder="your@company-name.com"
                className="text-brand-lighest w-full flex-1 rounded-sm bg-brand-dark px-3 py-2.5 text-sm placeholder-brand-lightest/80 outline-none ring-1 ring-brand-lightest"
              />

              <div className="flex w-full flex-1 flex-row items-center justify-between gap-x-2">
                <Checkbox
                  aria-required
                  name="privacyPolicy"
                  id="privacy-policy"
                  label={
                    <>
                      I accept the&nbsp;
                      <Link to="/privacy-statement" className="underline underline-offset-4">
                        privacy statement
                      </Link>
                    </>
                  }
                />
                <Button
                  loading={fetcher.state !== 'idle'}
                  type="submit"
                  variant="outline"
                  size="lg"
                  className="rounded-sm hover:bg-brand-lightest/90 hover:ring-white">
                  Sign up
                </Button>
              </div>

              <p className="w-full">
                {emailError && <span className="text-red-200">Please enter a valid email address</span>}
                {privacyPolicyError && <span className="text-red-200">Please accept the privacy statement</span>}
              </p>
            </fetcher.Form>
          ) : (
            <p className="opacity-80">Thank you for joining the mailing list!</p>
          )}
        </div>
      </div>

      <div className="bg-white">
        <div
          className={classnames(
            'flex flex-col space-y-8 bg-white py-10 text-brand-darkest',
            'lg:h-14 lg:flex-row lg:items-center lg:justify-between lg:space-y-0 lg:py-0',
            MAX_WIDTH,
            PADDING
          )}>
          <nav className={classnames('flex flex-col gap-y-2 text-xs', 'lg:flex-row lg:items-center lg:gap-x-8')}>
            <div className="font-bold">© 2024 Routescanner</div>
            <div>Responsible Disclosure</div>
            <div>Disclaimer</div>
            <div>Privacy</div>
            <div>Copyright</div>
            <div>Cookies</div>
          </nav>

          <nav className={classnames('flex flex-row gap-x-4')}>
            <Socials.LinkedinIcon className="size-6" />
            <Socials.YoutubeIcon className="size-6" />
            <Socials.TwitterIcon className="size-6" />
            <Socials.InstagramIcon className="size-6" />
          </nav>
        </div>
      </div>
    </footer>
  )
}

import { memo } from 'react'
import type { SVGProps } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 25" {...props}>
    <path
      fill="currentColor"
      d="M12 9.353a2.857 2.857 0 1 0 .002 5.715A2.857 2.857 0 0 0 12 9.353Zm6.68-2.196a2.892 2.892 0 0 0-1.628-1.63c-1.125-.443-3.804-.344-5.052-.344-1.248 0-3.924-.103-5.052.345a2.893 2.893 0 0 0-1.63 1.629c-.443 1.125-.344 3.806-.344 5.053 0 1.247-.099 3.926.347 5.054a2.893 2.893 0 0 0 1.629 1.63c1.125.444 3.803.344 5.052.344 1.249 0 3.924.103 5.052-.345a2.893 2.893 0 0 0 1.629-1.629c.447-1.125.345-3.806.345-5.053 0-1.247.102-3.925-.345-5.053l-.002-.001ZM12 16.604a4.393 4.393 0 1 1 0-8.786 4.393 4.393 0 0 1 0 8.786Zm4.574-7.945a1.025 1.025 0 1 1 .727-.3 1.024 1.024 0 0 1-.725.302l-.002-.002ZM21.429.211H2.57A2.571 2.571 0 0 0 0 2.782V21.64a2.572 2.572 0 0 0 2.571 2.57H21.43A2.571 2.571 0 0 0 24 21.64V2.782A2.571 2.571 0 0 0 21.429.211Zm-.918 15.536c-.069 1.373-.382 2.59-1.384 3.589-1.003 1-2.218 1.32-3.59 1.385-1.415.08-5.656.08-7.071 0-1.373-.07-2.585-.383-3.59-1.385-1.003-1.002-1.319-2.219-1.384-3.59-.08-1.415-.08-5.657 0-7.07.069-1.374.379-2.59 1.385-3.59S7.098 3.77 8.466 3.705c1.415-.08 5.657-.08 7.071 0 1.373.069 2.59.383 3.59 1.385 1 1.001 1.32 2.219 1.384 3.592.08 1.41.08 5.648 0 7.065Z"
    />
  </svg>
)

const Memo = memo(SvgComponent)

export default Memo

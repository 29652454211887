import { memo } from 'react'
import type { SVGProps } from 'react'

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 24 25" {...props}>
    <path
      fill="currentColor"
      d="M21.429.21H2.57A2.572 2.572 0 0 0 0 2.783V21.64a2.572 2.572 0 0 0 2.571 2.57H21.43A2.572 2.572 0 0 0 24 21.64V2.782A2.572 2.572 0 0 0 21.429.211Zm-2.62 8.508c.01.15.01.305.01.455 0 4.645-3.535 9.997-9.996 9.997a9.95 9.95 0 0 1-5.394-1.575c.284.032.557.043.846.043a7.044 7.044 0 0 0 4.36-1.5A3.518 3.518 0 0 1 5.353 13.7c.54.08 1.028.08 1.585-.064a3.514 3.514 0 0 1-2.812-3.45v-.043a3.51 3.51 0 0 0 1.586.445 3.506 3.506 0 0 1-1.565-2.925c0-.654.172-1.254.477-1.773a9.976 9.976 0 0 0 7.243 3.675c-.498-2.384 1.286-4.318 3.429-4.318a3.5 3.5 0 0 1 2.566 1.109 6.885 6.885 0 0 0 2.228-.847 3.504 3.504 0 0 1-1.543 1.934 6.99 6.99 0 0 0 2.025-.546 7.396 7.396 0 0 1-1.762 1.821Z"
    />
  </svg>
)

const Memo = memo(SvgComponent)

export default Memo
